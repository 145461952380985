import React from 'react';

const OverviewStats = ({ overview }) => {
    return (
        <div className="overview-stats border border-1 border-gray-300 rounded bg-white px-4 py-8 relative w-3/5">
            <h2 className="italic font-bold absolute top-[-24px] left-3 bg-white p-2">OVERVIEW</h2>
            <div className="grid grid-cols-6 mb-8">
                <div className="text-center">
                    <p className='font-bold text-gray-300 text-xs mb-1'>OUTSTANDING</p>
                    <p className='font-bold mb-1'>{Math.round(overview.loans.outstanding.amount)} ADA</p>
                    <p className='text-sm mb-1 text-gray-700'>({Math.round(overview.loans.outstanding.count)} loans)</p>
                </div>
                <div className="text-center">
                    <p className='font-bold text-gray-300 text-xs mb-1'>UNDERWATER</p>
                    <p className='font-bold mb-1'>{overview.loans.underwater.amount} ADA</p>
                    <p className='text-sm mb-1 text-gray-700'>({overview.loans.underwater.count} loans)</p>
                </div>
                <div className="text-center">
                    <p className='font-bold text-gray-300 text-xs mb-1'>EXPIRING</p>
                    <p className='font-bold mb-1'>{Math.round(overview.loans.expiring.amount)} ADA</p>
                    <p className='text-sm mb-1 text-gray-700'>({Math.round(overview.loans.expiring.count)} loans)</p>
                </div>
            </div>
            <div className="grid grid-cols-6">
                <div className="text-center">
                    <p className='font-bold text-gray-300 text-xs mb-1'>OUTSTANDING</p>
                    <p className='font-bold mb-1'>{Math.round(overview.liqbids.outstanding.amount)} ADA</p>
                    <p className='text-sm mb-1 text-gray-700'>({Math.round(overview.liqbids.outstanding.count)} liqbids)</p>
                </div>
                <div className="text-center">
                    <p className='font-bold text-gray-300 text-xs mb-1'>UNDERWATER</p>
                    <p className='font-bold mb-1'>{overview.liqbids.underwater.amount} ADA</p>
                    <p className='text-sm mb-1 text-gray-700'>({overview.liqbids.underwater.count} liqbids)</p>
                </div>
                <div className="text-center">
                    <p className='font-bold text-gray-300 text-xs mb-1'>IDLE</p>
                    <p className='font-bold mb-1'>{Math.round(overview.liqbids.idle.amount)} ADA</p>
                    <p className='text-sm mb-1 text-gray-700'>({Math.round(overview.liqbids.idle.count)} liqbids)</p>
                </div>
                {/* <div className="text-center">
                    <p className='font-bold text-gray-300 text-xs mb-1'>RANKS</p>
                    <p className='font-bold mb-1'>{overview.liqbids.ranks.high}</p>
                </div>*/}
                <div className="text-center">
                    <p className='font-bold text-gray-300 text-xs mb-1'>OPPORTUNITIES</p>
                    <p className='font-bold mb-1'>{overview.liqbids.opportunities.amount} ADA</p>
                    <p className='text-sm mb-1 text-gray-700'>({overview.liqbids.opportunities.count} liqbids)</p>
                </div> 
            </div>
        </div>
    );
};

export default OverviewStats;
