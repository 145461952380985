import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { initializeGA, logPageView } from './ga';
import Dashboard from './Dashboard';
import Homepage from './Homepage'; // Assuming you have a Homepage component
import WalletOverview from './WalletOverview';
import './App.css';

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView(location);
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    initializeGA();
  }, []);

  return (
    <Router>
      <RouteChangeTracker />
      <div className="App container p-12">
        {/* Define your routes */}
        <Routes>
          {/* Route for the homepage */}
          <Route path="/" element={<Homepage />} />

          {/* Route for the dashboard, which becomes visible only when navigating to /dashboard */}
          <Route path="/nft" element={<Dashboard endpoint='api/levvy/nft/lender/metrics' />} />
          <Route path="/token" element={<Dashboard endpoint='api/levvy/token/lender/metrics' />} />
          {/* You can add more routes as needed */}
          <Route path="/wallet" element={<WalletOverview />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
