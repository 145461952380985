import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OverviewStats from './OverviewStats';
import LoanItem from './LoanItem';
import LiqbidItem from './LiqbidItem';
import { useSearchParams } from 'react-router-dom';

const developmentMode = process.env.REACT_APP_NODE_ENV !== 'production';

const WalletOverview = () => {
    const [searchParams] = useSearchParams();
    const walletAddress = searchParams.get('address');
    const [overview, setOverview] = useState(null);
    const [loans, setLoans] = useState([]);
    const [liqbids, setLiqbids] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isNFT, setIsNFT] = useState(true); // Toggle between NFT and token data

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (!isNFT) {
                    const apiUrl = developmentMode ? 'http://localhost:5000' : 'https://liqbidscanner-2e5923a1a887.herokuapp.com';
                    const overviewResponse = await axios.get(`${apiUrl}/api/levvy/token/wallet/lender/overview?address=${walletAddress}`);
                    const loansResponse = await axios.get(`${apiUrl}/api/levvy/token/wallet/lender/current-loans?address=${walletAddress}`);
                    const liqbidsResponse = await axios.get(`${apiUrl}/api/levvy/token/wallet/lender/current-liqbids?address=${walletAddress}`);

                    console.log(loansResponse.data)

                    setOverview(overviewResponse.data);
                    setLoans(loansResponse.data);
                    setLiqbids(liqbidsResponse.data);
                    setLoading(false);
                } else {
                    const apiUrl = developmentMode ? 'http://localhost:5000' : 'https://liqbidscanner-2e5923a1a887.herokuapp.com';
                    const overviewResponse = await axios.get(`${apiUrl}/api/levvy/nft/wallet/lender/overview?address=${walletAddress}`);
                    const loansResponse = await axios.get(`${apiUrl}/api/levvy/nft/wallet/lender/current-loans?address=${walletAddress}`);
                    const liqbidsResponse = await axios.get(`${apiUrl}/api/levvy/nft/wallet/lender/current-liqbids?address=${walletAddress}`);

                    console.log(loansResponse.data)

                    setOverview(overviewResponse.data);
                    setLoans(loansResponse.data);
                    setLiqbids(liqbidsResponse.data);
                    setLoading(false);
                }
            } catch (err) {
                console.error('Error fetching wallet data:', err);
                setError('Failed to load data');
                setLoading(false);
            }
        };

        fetchData();
    }, [walletAddress, isNFT]);

    const toggleDataView = () => {
        setIsNFT(!isNFT);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="wallet-overview">
            <div className="toggle-switch absolute top-4 right-8 px-8 py-2 bg-sky-200 text-sky-900 font-bold rounded">
                <button onClick={toggleDataView}>{isNFT ? 'Switch to Token' : 'Switch to NFT'}</button>
            </div>
            {overview && <OverviewStats overview={overview} />}
            <div className='grid grid-cols-2 gap-12 mt-12 '>
                <div className="your-loans border border-1 border-gray-300 rounded bg-white p-4 relative">
                    <h2 className="italic font-bold absolute top-[-24px] left-3 bg-white p-2">YOUR LOANS</h2>
                    {loans.map(loan => <LoanItem key={loan.id} loan={loan} />)}
                </div>
                <div className="your-liqbids border border-1 border-gray-300 rounded bg-white p-4 relative">
                    <h2 className="italic font-bold absolute top-[-24px] left-3 bg-white p-2">YOUR LIQBIDS</h2>
                    {liqbids.map(liqbid => <LiqbidItem key={liqbid.id} liqbid={liqbid} />)}
                </div>
            </div>
        </div>
    );
};

export default WalletOverview;