import ReactGA from 'react-ga4';

const initializeGA = () => {
  ReactGA.initialize('G-PYYFD7MLZ4');
};

const logPageView = (location) => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
  };

export { initializeGA, logPageView };
