import React, { useState, useRef, useEffect } from 'react';

const Tooltip = ({ description }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState("left");
    const iconRef = useRef(null); // Reference to the icon

    // Show tooltip
    const handleMouseEnter = () => {
        setIsVisible(true);
        updateTooltipPosition();
    };

    // Hide tooltip
    const handleMouseLeave = () => {
        setIsVisible(false);
    };

    // Update the tooltip position based on the icon position
    const updateTooltipPosition = () => {
        if (iconRef.current) {
            const iconRect = iconRef.current.getBoundingClientRect();
            const viewportWidth = window.innerWidth;

            // Check if there's enough space on the right
            if (viewportWidth - iconRect.right > 384) { // Assuming tooltip width of around 200px
                setPosition("left");
            } else {
                setPosition("right");
            }
        }
    };

    // Adjust position on window resize
    useEffect(() => {
        const handleResize = () => {
            if (isVisible) {
                updateTooltipPosition();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isVisible]);

    return (
        <div className="relative inline-block" onMouseLeave={handleMouseLeave} ref={iconRef}>
            <span onMouseEnter={handleMouseEnter} className="cursor-help opacity-50 ml-1">
                ℹ️
            </span>
            {isVisible && (
                <div className={`absolute z-10 w-96 max-w-sm p-2 bg-gray-900 text-white shadow-lg rounded border border-gray-300 top-full ${position === 'left' ? 'left-0' : 'right-0'}`} onMouseEnter={handleMouseEnter} style={{ marginTop: '4px' }}>
                    {description}
                </div>
            )}
        </div>
    );
};


export default Tooltip;