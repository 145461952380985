import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const PriceGraphTooltip = ({ priceData }) => {
  if (!priceData || priceData.length === 0) {
    return <div>No data available</div>;
  }

  const data = {
    labels: priceData.map(entry => 
      new Date(entry.time * 1000).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit'
      })
    ),
    datasets: [{
      label: 'Price Over Time',
      data: priceData.map(entry => entry.close),
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  const options = {
    scales: {
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Price (₳)'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Date'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    elements: {
      point:{
        radius: 0 // Hides points on the line (optional)
      }
    },
    maintainAspectRatio: false // Ensures that aspect ratio is not maintained; adjust per your styling needs
  };

  return (
    <div style={{ width: 300, height: 200 }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default PriceGraphTooltip;
