import React, { useEffect, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import './DataTable.css'; // Import the CSS for this component
import Tooltip from './Tooltip';
import PriceGraphTooltip from './PriceGraphTooltip';
import axios from 'axios';

const developmentMode = process.env.REACT_APP_NODE_ENV !== 'production';

// This function converts a hexidecimal string to Ascii
function hexToAscii(hexString) {
    let asciiString = '';
    if (!hexString) { console.log('No hexstring to convert to ascii'); return; }
    for (let i = 0; i < hexString.length; i += 2) {
        const hexChunk = hexString.substring(i, i + 2);
        const asciiCharacter = String.fromCharCode(parseInt(hexChunk, 16));
        asciiString += asciiCharacter;
    }
    return asciiString;
}

const customSort = (valueA, valueB) => {
    // Handle undefined or null values
    if (valueA === null || valueA === undefined) valueA = '';
    if (valueB === null || valueB === undefined) valueB = '';

    // Comparison logic for numbers
    if (!isNaN(valueA) && !isNaN(valueB)) {
        return parseFloat(valueB) - parseFloat(valueA);
    }

    // Comparison logic for strings
    return valueA.toString().localeCompare(valueB.toString());
};

// Mock function to fetch tooltip data
const fetchTooltipData = async (policyId) => {
    try {
        // Replace with your actual API call
        const apiUrl = developmentMode ? 'http://localhost:5000' : 'https://liqbidscanner-2e5923a1a887.herokuapp.com';
        const response = await axios.get(`${apiUrl}/api/price-over-time/?policy_id=${policyId}`);
        const data = await response.data;
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching tooltip data: ', error);
        return false;
    }
};

const DataTable = ({ data, filters }) => {
    const [isToken, setIsToken] = useState(false);
    const [filteredData, setFilteredData] = useState(data);
    const [tooltipData, setTooltipData] = useState({});

    const adjustTooltipPosition = (e) => {
        const tooltip = e.currentTarget.querySelector('.tooltiptext');
        if (!tooltip) return;

        console.log('Tooltip adjust function called.');

        tooltip.style.left = '';
        tooltip.style.top = '';
        tooltip.style.transform = '';

        const tooltipRect = tooltip.getBoundingClientRect();
        const shiftLeft = tooltipRect.right > window.innerWidth;
        const shiftUp = tooltipRect.bottom > window.innerHeight;

        if (shiftLeft) {
            tooltip.style.right = '0px';
            tooltip.style.left = 'auto';
        }
        if (shiftUp) {
            tooltip.style.top = 'auto';
            tooltip.style.bottom = '100%';
        }
    };


    useEffect(() => {
        let newFilteredData = [...data];

        if (filters) {
            if (filters.discrepancy && filters.discrepancy.enabled) {
                const discrepancyFilter = filters.discrepancy;
                newFilteredData = newFilteredData.filter(item => {
                    const discrepancyValue = item.discrepancy_ratio_24h;
                    return discrepancyFilter.condition === 'above' ? discrepancyValue > discrepancyFilter.value : discrepancyValue < discrepancyFilter.value;
                });
            }
            if (filters.ltlv && filters.ltlv.enabled) {
                const ltlvFilter = filters.ltlv;
                newFilteredData = newFilteredData.filter(item => {
                    const ltlvValue = item.ltlv;
                    return ltlvFilter.condition === 'above' ? ltlvValue > ltlvFilter.value : ltlvValue < ltlvFilter.value;
                });
            }
            if (filters.activeLoans && filters.activeLoans.enabled) {
                const activeLoansFilter = filters.activeLoans;
                newFilteredData = newFilteredData.filter(item => {
                    const activeLoansValue = item.loans_outstanding.amount;
                    return activeLoansFilter.condition === 'above' ? activeLoansValue > activeLoansFilter.value : activeLoansFilter.condition === 'below' ? activeLoansValue < activeLoansFilter.value : true;
                });
            }
            if (filters.underwaterLoans && filters.underwaterLoans.enabled) {
                const underwaterLoansFilter = filters.underwaterLoans;
                newFilteredData = newFilteredData.filter(item => {
                    const underwaterLoansValue = item.loans_underwater.amount;
                    return underwaterLoansFilter.condition === 'above' ? underwaterLoansValue > underwaterLoansFilter.value : underwaterLoansValue < underwaterLoansFilter.value;
                });
            }
        }

        setFilteredData(newFilteredData);
    }, [data, filters]);

    useEffect(() => {
        setIsToken(!!data && !!data[0] && !!data[0].token_title);
    }, [data]);

    useEffect(() => {
        const fetchAllTooltips = async () => {
            const newTooltipData = {};
            await Promise.all(filteredData.map(async (item) => {
                console.log('Fetching tooltip data for item: ', item.policy_id)
                if (!isToken && item.policy_id) {
                    const data = await fetchTooltipData(item.policy_id);
                    newTooltipData[item.policy_id] = data;
                }
            }));
            setTooltipData(prevData => {
                const updatedData = { ...prevData, ...newTooltipData };
                console.log('Updated tooltipData:', updatedData); // Debug log
                return updatedData;
            });
        };
        fetchAllTooltips();
    }, [filteredData, isToken]);

    const columns = React.useMemo(() => [
        {
            Header: isToken ? 'Token' : 'Collection',
            accessor: row => row.collection_title ? row.collection_title.length > 26 ? row.collection_title.substring(0, 25 - 3) + '...' : row.collection_title : row.token_title ? hexToAscii(row.token_title) : row.policy_id.substring(0, 20) + '...',
            Cell: ({ row }) => (
                <div key={row.original.id} onMouseEnter={adjustTooltipPosition}>
                    {row.original.collection_title ? row.original.collection_title.length > 26 ? row.original.collection_title.substring(0, 25 - 3) + '...' : row.original.collection_title : row.original.token_title ? hexToAscii(row.original.token_title) : row.original.policy_id.substring(0, 20) + '...'}
                    {isToken ? '' :
                        <div className="tooltip text-2xs ml-2 px-1 bg-gray-200 rounded text-gray-800">STATS
                            <span className="tooltiptext text-xs w-80">
                                <h6 className='text-center text-gray-300 text-2xs font-semibold mb-1'>TRADING STATISTICS</h6>
                                <div className='grid grid-cols-2'>
                                    <div>
                                        <b>Floor:</b> {row.original.stats.price}₳<br />
                                        <b>Col. offer:</b> {row.original.stats.topOffer}₳<br />
                                        <b>Listings:</b> {row.original.stats.listings} ({Math.round((row.original.stats.listings / row.original.stats.supply) * 10000) / 100}%)<br />
                                    </div>
                                    <div>
                                        <b>Volume:</b> {Math.round(row.original.stats.volume)}₳<br />
                                        <b>Owners:</b> {row.original.stats.owners}<br />
                                        <b>Supply:</b> {row.original.stats.supply}<br />
                                    </div>
                                </div>
                                <hr className="my-3 border-gray-600" />
                                <div className="price-graph">
                                    {tooltipData[row.original.policy_id] ? <PriceGraphTooltip priceData={tooltipData[row.original.policy_id]} /> : 'Loading floorprice history...'}    
                                </div>
                            </span>
                        </div>}
                </div>
            ),
            id: 'collection',
            disableSortBy: false
        },
        {
            Header: 'Active loans',
            accessor: 'loans_outstanding',
            // Custom cell render to display amount and count
            Cell: ({ value }) => `${value.amount ? value.amount + ' ₳' : '-'}\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0${value ? value.count + ' #' : '-'}`,
            description: 'The total amount (in ₳) and count of active loans outstanding. These are loans picked up by a borrower that have not been repaid or foreclosed yet.',
            disableSortBy: false, // Enable sorting on this column
            sortType: (a, b) => customSort(a.original.loans_outstanding.amount, b.original.loans_outstanding.amount)
        },
        {
            Header: 'Underwater loans',
            accessor: 'loans_underwater',
            Cell: ({ value }) => `${value.amount ? value.amount + ' ₳' : '-'}\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0${value.percentage ? value.percentage + '%' : '-'}`,
            description: `The total amount (in ₳) and count of active loans that are underwater. These are active loans for which the collateral in its most liquid form (i.e. for what it can immediately be sold via a collection offer, taking into account exchange fees) is worth less then the total that needs to be repaid. In other words, active loans where letting the loan default is the borrower's most lucrative decision.`,
            disableSortBy: false, // Enable sorting on this column
            sortType: (a, b) => customSort(a.original.loans_underwater.amount, b.original.loans_underwater.amount) // Custom sorting based on amount
        },
        {
            Header: 'Expiring in 24h',
            accessor: 'upcoming_expiration',
            Cell: ({ value }) => `${value.amount ? value.amount + ' ₳' : '-'}\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0${value ? value.count + ' #' : '-'}`,
            description: 'The total amount (in ₳) and count of active loans that are about to expire within the next 24 hours.',
            disableSortBy: false, // Enable sorting on this column
            sortType: (a, b) => customSort(a.original.upcoming_expiration.amount, b.original.upcoming_expiration.amount) // Custom sorting based on amount
        },
        {
            Header: 'Default rate (24h | 7d | 30d)',
            accessor: 'default_rate',
            Cell: ({ value }) => `${value['24h'] ? value['24h'] + '%' : '-'}\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0${value['7d'] ? value['7d'] + '%' : '-'}\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0${value['30d'] ? value['30d'] + '%' : '-'}`,
            description: 'The average default rate of loans (i.e. % of defaults compared to total loan closures) over the past 24 hours, 7 days, and 30 days.',
            disableSortBy: false, // Enable sorting on this column
            sortType: (a, b) => customSort(a.original.default_rate['24h'], b.original.default_rate['24h']) // Custom sorting based on amount
        },
        {
            Header: 'Open liqbids',
            accessor: 'liqbids_outstanding',
            Cell: ({ value }) => `${!isToken ? value.highest_liqbid ? value.highest_liqbid + ' ₳' : '-' : value.amount + ' ₳'}\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0${value ? value.count + ' #' : '-'}`,
            description: `${!isToken ? 'The highest liquidity bid available (i.e. offer by a lender to loan ₳)' : 'The total amount'} and count of all open liquidity bids.`,
            disableSortBy: false, // Enable sorting on this column
            sortType: (a, b) => customSort(a.original.liqbids_outstanding.highest_liqbid, b.original.liqbids_outstanding.highest_liqbid) // Custom sorting based on amount
        },
        {
            Header: 'Discrepancy (24h)',
            accessor: 'discrepancy_ratio_24h',
            Cell: ({ value }) => value > 0 ? `+${value}` : value, // Add "+" sign for positive values
            description: 'Compares the average amount of loans borrowed per 24 hours over the past 14 days, to the amount of loans borrowed in the last 24 hours. Can provide an indication of whether the past 24 hours has seen comparatively low amount of loans borrowed (and we could expect to see more) or high amount of loans borrowed (and we could expect it to be saturated).',
            disableSortBy: false, // Enable sorting on this column
            sortType: (a, b) => customSort(a.original.discrepancy_ratio_24h, b.original.discrepancy_ratio_24h) // Custom sorting based on amount
        },
        {
            Header: isToken ? 'LTV' : 'LTLV',
            accessor: 'ltlv',
            Cell: ({ value }) => `${value ? value + '%' : '-'}`,
            description: isToken ? 'The Loan to Value ratio provides an indication of the margin of safety on the collateral. It compares the ₳ value of the loan that needs to be offered (highest liquidity bid) to the ₳ value of the collateral in its most liquid form (i.e. for what it can immediately be sold).' : 'The Loan to Liquid Value ratio provides an indication of the margin of safety on the collateral. It compares the ₳ value of the loan that needs to be offered (highest liquidity bid) to the ₳ value of the collateral in its most liquid form (i.e. for what it can immediately be sold via a collection offer, taking into account exchange fees).',
            disableSortBy: false, // Enable sorting on this column
            sortType: (a, b) => customSort(a.original.ltlv, b.original.ltlv) // Custom sorting based on amount
        }
    ], [isToken, tooltipData]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: filteredData }, useSortBy);

    return (
        <table {...getTableProps()} className="min-w-full divide-y divide-gray-400 border border-gray-400 rounded">
            <thead className="bg-gray-100">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="px-6 py-3 text-left text-2xs font-semibold text-gray-800 uppercase tracking-wider border-r border-gray-400">
                                <div className="text-center relative">
                                    {column.render('Header')}
                                    {column.isSorted ? (column.isSortedDesc ? ' 🔼' : ' 🔽') : ''}
                                    {column.description && (
                                        <Tooltip description={column.description} />
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-400">
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                let cellClass = 'text-gray-900';
                                if (cell.column.id === 'discrepancy_ratio_24h') {
                                    if (cell.value <= 2 && cell.value >= -2) {
                                        cellClass = 'text-yellow-500';
                                    } else if (cell.value > 2) {
                                        cellClass = 'text-red-500';
                                    } else {
                                        cellClass = 'text-green-500';
                                    }
                                } else if (cell.column.id === 'ltlv') {
                                    if (cell.value <= 95 && cell.value >= 85) {
                                        cellClass = 'text-yellow-500';
                                    } else if (cell.value > 95) {
                                        cellClass = 'text-red-500';
                                    } else {
                                        cellClass = 'text-green-500';
                                    }
                                }

                                const isCollectionColumn = cell.column.id === 'collection';
                                const textLeftClass = isCollectionColumn ? 'text-left' : '';

                                return (
                                    <td {...cell.getCellProps()} className={`px-6 py-3 whitespace-nowrap text-sm border-r border-gray-400 ${cellClass} ${textLeftClass}`}>
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};


export default DataTable;