import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from './DataTable';
import Filters from './Filters';
import { Link, useLocation } from 'react-router-dom';

const developmentMode = process.env.REACT_APP_NODE_ENV !== 'production';

const Dashboard = ({ endpoint }) => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const [filters, setFilters] = useState({
    // Initial filter states
    loansOutstanding: null,
    underwaterLoans: null,
    discrepancy: null,
    ltv: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = developmentMode ? 'http://localhost:5000' : 'https://liqbidscanner-2e5923a1a887.herokuapp.com';
        const response = await axios.get(`${apiUrl}/${endpoint}`);
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [endpoint]);

  // Handle changes in filters
  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  return (
    <div>
      <div className='flex justify-between items-center p-4'>
        <div className='text-gray-900 text-md'>
        <Link to="/" className={`mr-4 p-2 ${location.pathname === '/' ? 'text-blue-600 border-b-4 border-blue-500' : ''}`}>Home</Link>
          <Link to="/nft" className={`mr-4 p-2 ${location.pathname === '/nft' ? 'text-blue-600 border-b-4 border-blue-500' : ''}`}>NFT</Link>
          <Link to="/token" className={`p-2 ${location.pathname === '/token' ? 'text-blue-600 border-b-4 border-blue-500' : ''}`}>Token</Link>
        </div>
      </div>
      <div className='py-8 px-12'>
        <div className='wrapper text-left'>
          <div className='note py-1 px-3 mb-3 text-sm bg-orange-100 border border-1 border-orange-300 inline-block text-gray-800'><b>Attention:</b> The data in this dashboard is incomplete. It is still being populated and has not been verified.</div>
        </div>
        <Filters onFilterChange={handleFilterChange} />
        <DataTable data={data} filters={filters} />
      </div>
    </div>
  );
};

export default Dashboard;
