import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css'; // Make sure to import your CSS if needed

function Homepage() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);
    const intervalRef = useRef(null); // Use useRef to persist the interval ID

    // Placeholder images
    const content = [
        {
            src: '/table.png',
            tagline: 'Asses loan-risk across collections and tokens'
        },
        {
            src: '/notifications.png',
            tagline: 'Receive personalized portfolio notifications'
        }
    ];

    // This function sets up the interval for changing slides
    const setupSlideChangeInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
            setIsFading(true); // Start fading out
            setTimeout(() => {
                setActiveIndex((prevIndex) => (prevIndex + 1) % content.length); // Change the content
                setIsFading(false); // Fade in new content
            }, 1000); // This should match your fadeOut animation time
        }, 5000); // Change slide every 5 seconds
    };

    useEffect(() => {
        setupSlideChangeInterval();

        // Clear interval on component unmount
        return () => clearInterval(intervalRef.current);
    }, []);

    const handleDotClick = (index) => {
        if (index !== activeIndex) {
            setIsFading(true); // Start fading out
            setTimeout(() => {
                setActiveIndex(index); // Change the content immediately
                setIsFading(false); // Start fading in
                setupSlideChangeInterval(); // Set up a new interval
            }, 1000); // This should match your fadeOut animation time
        }
    };

    return (
        <div className="App flex h-screen min-h-[600px]">
            <div className='logo absolute top-10 left-10 font-bold text-xl tracking-wide'>
                JPEG.LOAN
            </div>
            <div className="w-1/2 flex flex-col justify-center p-28 text-left">
                <h1 className="text-3xl lg:text-4xl xl:text-5xl lg:leading-tight xl:leading-tight font-bold mb-12">Insight and alerts for lenders on Cardano</h1>
                <p className="mb-20 text-lg xl:text-xl xl:leading-relaxed leading-relaxed">Assess loan-risk across collections and tokens and stay updated on your portfolio with personalized notifications</p>
                <div className='flex flex-col md:flex-row text-left space-y-4 md:space-y-0 md:space-x-4'>
                    <Link to="/nft" className="inline-block bg-black text-white py-2 px-8 font-bold mr-4">View dashboard</Link>
                    <a href="https://discord.gg/RETa8aYX" target="_blank" rel="noopener noreferrer" className="inline-block border border-1 border-black text-black py-2 px-8 font-semibold">Get notifications in Discord</a>
                </div>
            </div>
            <div className="w-1/2 flex flex-col justify-center items-center bg-[#F6F9FF] p-28">
                <div className={`content ${isFading ? 'fadeOut' : 'fadeIn'} ${content[activeIndex].src == '/notifications.png' ? 'px-16' : ''}`}>
                    <img src={content[activeIndex].src} alt="Display" className='mb-8' />
                    <p className='font-semibold'>{content[activeIndex].tagline}</p>
                </div>
                <div className="dots absolute bottom-10">
                    {content.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === activeIndex ? 'active' : ''}`}
                            onClick={() => handleDotClick(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Homepage;