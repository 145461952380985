import React, { useEffect, useState } from 'react';
import axios from 'axios';

const developmentMode = process.env.REACT_APP_NODE_ENV !== 'production';

function formatDuration(duration_in_s) {
    // Format it in a way that it is human readable and only returns highest unit
    const days = Math.floor(duration_in_s / 86400);
    const hours = Math.floor((duration_in_s % 86400) / 3600);
    const minutes = Math.floor((duration_in_s % 3600) / 60);
    const seconds = duration_in_s % 60;

    if (days > 0) {
        return `${days} days`;
    } else if (hours > 0) {
        return `${hours} hours`;
    } else if (minutes > 0) {
        return `${minutes} minutes`;
    } else {
        return `${seconds} seconds`;
    }
}

function calculateTimeDifference(tx_timestamp) {
    // return idle time in highest unit 
    const tx_time = new Date(tx_timestamp * 1000);
    const now = new Date();
    const diff = now > tx_time ? now - tx_time : tx_time - now;
    const diff_in_s = Math.floor(diff / 1000);

    return formatDuration(diff_in_s);
}

// Make call to api to get LTLV
async function fetchLTLVForCollection(policy_id, amount) {
    const apiUrl = developmentMode ? 'http://localhost:5000' : 'https://liqbidscanner-2e5923a1a887.herokuapp.com';
    try {
        const response = await axios.get(`${apiUrl}/api/levvy/nft/collection/ltlv?policy_id=${policy_id}&amount=${amount}`);
        return response.data; // Make sure the API returns just the value or adjust this line accordingly.
    } catch (err) {
        console.error('Error fetching LTLV data:', err);
        return null;
    }
}

// Make call to api to get LTLV
async function fetchDiscrepancyForCollection(policy_id) {
    const apiUrl = developmentMode ? 'http://localhost:5000' : 'https://liqbidscanner-2e5923a1a887.herokuapp.com';
    try {
        const response = await axios.get(`${apiUrl}/api/levvy/nft/collection/discrepancy?policy_id=${policy_id}`);
        return response.data; // Make sure the API returns just the value or adjust this line accordingly.
    } catch (err) {
        console.error('Error fetching LTLV data:', err);
        return null;
    }
}

async function fetchRankForLiqbid(policy_id, amount, utxo_id, tx_timestamp) {
    const apiUrl = developmentMode ? 'http://localhost:5000' : 'https://liqbidscanner-2e5923a1a887.herokuapp.com';
    try {
        const response = await axios.get(`${apiUrl}/api/levvy/nft/liqbid/rank?policy_id=${policy_id}&amount=${amount}&utxo_id=${utxo_id}&tx_timestamp=${tx_timestamp}`);
        return response.data; // Make sure the API returns just the value or adjust this line accordingly.
    } catch (err) {
        console.error('Error fetching Rank data:', err);
        return null;
    }
}

async function fetchCollectionImage(policy_id) {
    try {
        const response = await axios.get(`https://openapi.taptools.io/api/v1/nft/collection/assets?policy=${policy_id}&sortBy=rank&order=desc`, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'x-api-key': 'E218aXIBg3oTodgKph72ZGjzjOO79ZJ7'
            }
        })
        console.log(response.data)
        const assetData = response.data[0] || null;
        return assetData ? convertIPFSUrl(assetData.image) : null;
    } catch (err) {
        console.error('Error fetching asset image:', err);
        return null;
    }
}

function convertIPFSUrl(url) {
    const ipfsPrefix = 'ipfs://';
    const ipfsGateway = 'https://ipfs.io/ipfs/';

    if (url.startsWith(ipfsPrefix)) {
        return `${ipfsGateway}${url.slice(ipfsPrefix.length)}`;
    }
    return url; // Return the original URL if it's not an IPFS link
}

// This function converts a hexidecimal string to Ascii
function hexToAscii(hexString) {
    let asciiString = '';
    if (!hexString) { console.log('No hexstring to convert to ascii'); return; }
    for (let i = 0; i < hexString.length; i += 2) {
        const hexChunk = hexString.substring(i, i + 2);
        const asciiCharacter = String.fromCharCode(parseInt(hexChunk, 16));
        asciiString += asciiCharacter;
    }
    return asciiString;
}

const LiqbidItem = ({ liqbid }) => {
    const [ltlv, setLTLV] = useState(null);
    const [discrepancy, setDiscrepancy] = useState(null);
    const [rank, setRank] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (liqbid.collection_title) {
            fetchLTLVForCollection(liqbid.policy_id, liqbid.amount).then(setLTLV);
            fetchDiscrepancyForCollection(liqbid.policy_id).then(setDiscrepancy);
            fetchRankForLiqbid(liqbid.policy_id, liqbid.amount, liqbid.utxo_id, liqbid.tx_timestamp).then(setRank);
            fetchCollectionImage(liqbid.policy_id).then(setImage);
        }
    }, [liqbid.policy_id, liqbid.amount, liqbid.utxo_id, liqbid.tx_timestamp]); // Dependencies for re-fetching if these values change.

    return (
        <div className="flex items-center justify-center">
            <div className="loan-item bg-white p-4 grid grid-cols-12 gap-8 text-left items-center">
                <img src={image ? `${image}` : 'https://placehold.co/200x200'} alt="Collateral" className="col-span-2 grayscale-[75%] border-2 border-black" />
                <div className='col-span-7'>
                    <h4>Liqbid for <span className="font-bold">{liqbid.token_title ? `${(liqbid.collateral / 10 ** liqbid.decimals)} ${hexToAscii(liqbid.token_title)}` : liqbid.collection_title}</span></h4>
                    <div className='grid grid-cols-4 gap-4'>
                        <div className='mt-[-3px]'>
                            <span className='text-2xs font-bold text-gray-300'>AMOUNT</span>
                            <p className='mt-[-5px]'>{Math.round(liqbid.amount)} ADA</p>
                        </div>
                        <div className='mt-[-3px]'>
                            <span className='text-2xs font-bold text-gray-300'>RETURN</span>
                            <p className='mt-[-5px]'>{Math.round(liqbid.interest)} ADA</p>
                        </div>
                        <div className='mt-[-3px]'>
                            <span className='text-2xs font-bold text-gray-300'>LTLV</span>
                            <p className='mt-[-5px]'>{liqbid.collection_title ? ltlv ? `${ltlv}%` : 'Loading...' : ''}</p>
                        </div>
                        <div className='mt-[-3px]'>
                            <span className='text-2xs font-bold text-gray-300'>RANK</span>
                            <p className='mt-[-5px]'>{liqbid.collection_title ? rank ? `#${rank}` : 'Loading...' : ''}</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-3 gap-3 italic text-xs mt-1 text-gray-400'>
                        <div className=''>
                            {calculateTimeDifference(liqbid.tx_timestamp)} idle
                        </div>
                        <div className=''>
                            {formatDuration(liqbid.duration)} duration
                        </div>
                        <div className=''>
                            {liqbid.collection_title ? discrepancy || discrepancy === 0 ? `${discrepancy} discrepancy` : 'Loading...' : ''}
                        </div>
                    </div>
                </div>
                <a href={`https://levvy.fi/manage`} className="text-center text-sm btn bg-black text-white font-bold px-4 py-2 col-span-3">View liqbid</a>
            </div>
        </div>
    );
};

export default LiqbidItem;