import React, { useState, useEffect } from 'react';

const Filters = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    activeLoans: { enabled: false, condition: 'above', value: 10000, descriptive: 'Active Loans', entity: '₳' },
    underwaterLoans: { enabled: false, condition: 'below', value: 5000, descriptive: 'Underwater Loans', entity: '₳' },
    discrepancy: { enabled: false, condition: 'below', value: -2, descriptive: 'Discrepancy', entity: '' },
    ltlv: { enabled: false, condition: 'below', value: 75, descriptive: 'LT(L)V', entity: '%' }
  });

  useEffect(() => {
    onFilterChange(filters);
  }, [filters, onFilterChange]);

  const handleChange = (name, key, value) => {
    setFilters(prevFilters => ({
        ...prevFilters,
        [name]: {
            ...prevFilters[name],
            [key]: key === 'value' ? (isNaN(value) ? '' : value) : value // Ensuring NaN values are replaced with '' for value changes
        }
    }));
};


  return (
    <div className="filters text-left mt-5 mb-5">
        <div className="inline mr-4">
            <b>Filters:</b> 
        </div>
      {Object.entries(filters).map(([name, filter]) => (
        <div className={`inline text-left p-2 px-4 mr-8 border rounded  ${filter.enabled ? 'border-blue-200' : 'border-gray-100 opacity-60'}`} key={name}>
          <label>
            <input
              type="checkbox"
              checked={filter.enabled}
              onChange={e => handleChange(name, 'enabled', e.target.checked)}
              className='mr-2'
            /> {filter.descriptive}
          </label>
          <select
            value={filter.condition}
            onChange={e => handleChange(name, 'condition', e.target.value)}
            disabled={!filter.enabled}
          >
            <option value="above">above</option>
            <option value="below">below</option>
          </select>
          <input
            type="number"
            value={filter.value}
            onChange={e => handleChange(name, 'value', parseInt(e.target.value, 10))}
            disabled={!filter.enabled}
            className={`${name == 'activeLoans' || name == 'underwaterLoans' ? 'w-24' : 'w-14'} mr-1`}
          />{filter.entity}
        </div>
      ))}
    </div>
  );
};

export default Filters;
