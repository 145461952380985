import React, { useEffect, useState } from 'react';
import axios from 'axios';

const taptools_api_key = process.env.TAPTOOLS_API_KEY;

const developmentMode = process.env.REACT_APP_NODE_ENV !== 'production';

function formatDuration(duration_in_s) {
    // Format it in a way that it is human readable and only returns highest unit
    const days = Math.floor(duration_in_s / 86400);
    const hours = Math.floor((duration_in_s % 86400) / 3600);
    const minutes = Math.floor((duration_in_s % 3600) / 60);
    const seconds = duration_in_s % 60;

    if (days > 0) {
        return `${days} days`;
    } else if (hours > 0) {
        return `${hours} hours`;
    } else if (minutes > 0) {
        return `${minutes} minutes`;
    } else {
        return `${seconds} seconds`;
    }
}

function calculateTimeDifference(tx_timestamp) {
    // return idle time in highest unit 
    const tx_time = new Date(tx_timestamp * 1000);
    const now = new Date();
    const diff = now > tx_time ? now - tx_time : tx_time - now;
    const diff_in_s = Math.floor(diff / 1000);

    return formatDuration(diff_in_s);
}


// Make call to api to get LTLV
async function fetchLTLVForCollection(policy_id, amount) {
    const apiUrl = developmentMode ? 'http://localhost:5000' : 'https://liqbidscanner-2e5923a1a887.herokuapp.com';
    try {
        const response = await axios.get(`${apiUrl}/api/levvy/nft/collection/ltlv?policy_id=${policy_id}&amount=${amount}`);
        return response.data; // Make sure the API returns just the value or adjust this line accordingly.
    } catch (err) {
        console.error('Error fetching LTLV data:', err);
        return null;
    }
}

async function fetchLTLVForToken(policy_id, token_title, amount, collateral, decimals) {
    const apiUrl = developmentMode ? 'http://localhost:5000' : 'https://liqbidscanner-2e5923a1a887.herokuapp.com';
    try {
        const response = await axios.get(`${apiUrl}/api/token/price?policy_id=${policy_id}&token_title=${token_title}`);
        const tokenPriceInAda = response.data;
        const ltlv = Math.round((amount / ((collateral / 10 ** decimals) * tokenPriceInAda)) * 100);
        return ltlv; // Make sure the API returns just the value or adjust this line accordingly.
    } catch (err) {
        console.error('Error fetching token price data:', err);
        return null;
    }
}

async function fetchAssetImage(policy_id, asset_name) {
    try {
        const response = await axios.get(`https://openapi.taptools.io/api/v1/nft/collection/assets?policy=${policy_id}&search=${hexToAscii(asset_name)}`, {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json',
                'x-api-key': 'E218aXIBg3oTodgKph72ZGjzjOO79ZJ7'
            }
        })
        console.log(response.data)
        const assetData = response.data[0] || null;
        return assetData ? convertIPFSUrl(assetData.image) : null;
    } catch (err) {
        console.error('Error fetching asset image:', err);
        return null;
    }
}


// This function converts a hexidecimal string to Ascii
function hexToAscii(hexString) {
    let asciiString = '';
    if (!hexString) { console.log('No hexstring to convert to ascii'); return; }
    for (let i = 0; i < hexString.length; i += 2) {
        const hexChunk = hexString.substring(i, i + 2);
        const asciiCharacter = String.fromCharCode(parseInt(hexChunk, 16));
        asciiString += asciiCharacter;
    }
    return asciiString;
}

function convertIPFSUrl(url) {
    const ipfsPrefix = 'ipfs://';
    const ipfsGateway = 'https://ipfs.io/ipfs/';

    if (url.startsWith(ipfsPrefix)) {
        return `${ipfsGateway}${url.slice(ipfsPrefix.length)}`;
    }
    return url; // Return the original URL if it's not an IPFS link
}


const LoanItem = ({ loan }) => {
    const [ltlv, setLTLV] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        if (loan.token_title) {
            fetchLTLVForToken(loan.policy_id, loan.token_title, loan.amount, loan.collateral, loan.decimals).then(setLTLV);
        } else {
            fetchLTLVForCollection(loan.policy_id, loan.amount).then(setLTLV);
            fetchAssetImage(loan.policy_id, loan.asset_name).then(setImage);
        }
    }, [loan.policy_id, loan.amount]);

    return (
        <div className="flex items-center justify-center">
            <div className="loan-item bg-white p-4 grid grid-cols-12 gap-8 text-left items-center">
                <img src={image ? `${image}` : 'https://placehold.co/200x200'} alt="Collateral" className="col-span-2 border-2 border-black" />
                <div className='col-span-7'>
                    <h4>
                        {loan.token_title ? `${loan.collateral / 10 ** loan.decimals} ` : ""}
                        <span className="font-bold">
                            {loan.token_title ? hexToAscii(loan.token_title) : hexToAscii(loan.asset_name)}
                        </span>
                        {loan.collection_title ? ` of ${loan.collection_title}` : ''}
                    </h4>
                    <div className='grid grid-cols-4 gap-4'>
                        <div className='mt-[-3px]'>
                            <span className='text-2xs font-bold text-gray-300'>AMOUNT</span>
                            <p className='mt-[-5px]'>{Math.round(loan.amount)} ADA</p>
                        </div>
                        <div className='mt-[-3px]'>
                            <span className='text-2xs font-bold text-gray-300'>RETURN</span>
                            <p className='mt-[-5px]'>{Math.round(loan.interest)} ADA</p>
                        </div>
                        <div className='mt-[-3px]'>
                            <span className='text-2xs font-bold text-gray-300'>LTLV</span>
                            <p className='mt-[-5px]'>{ltlv ? `${ltlv}%` : 'Loading...'}</p>
                        </div>
                    </div>
                    <div className='italic text-xs mt-1 text-gray-400'>
                        Expires in {calculateTimeDifference(loan.expiration_timestamp)}
                    </div>
                </div>
                <a href={`https://levvy.fi/manage`} className=" text-center text-sm btn bg-black text-white font-bold px-4 py-2 col-span-3">View loan</a>
            </div>
        </div>
    );
};

export default LoanItem;
